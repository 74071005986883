<template>
    <div class="notfind">
        <img src="@/assets/404.gif" alt="">
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .notfind{
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .notfind img{
        width: 100vw;
        height: 100vh;
    }
</style>